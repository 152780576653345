import { queryFetcher } from ".";
import { CurrencyItem } from "../../lib/db/meta-ads-currency-settings";

const ENDPOINTS = {
  updateMetaAdsCurrencySettings: () => "/api/meta-currency-conversion/update-currency-setting",

  fetchMetaAdsCurrencySettingByStoreID: ({ storeId }: { storeId: string }) =>
    `/api/meta-currency-conversion/currency-settings?storeId=${storeId}`,
};
function updatedMetaAdsCurrencySettingItem(props: { storeId: string; currencyItem: CurrencyItem }) {
  return queryFetcher(ENDPOINTS.updateMetaAdsCurrencySettings(), {
    method: "POST",
    body: JSON.stringify(props),
  });
}

export { ENDPOINTS, updatedMetaAdsCurrencySettingItem };
