import "../styles/nprogress.css";
import "../styles/globals.css";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import React, { useEffect } from "react";
import type { AppProps } from "next/app";
import nProgress from "nprogress";
import TagManager from "react-gtm-module";
import { Toaster } from "react-hot-toast";
import { useDetectAdBlock } from "adblock-detect-react";
import { QueryClient, QueryClientProvider } from "react-query";
import Head from "next/head";
import { Hydrate } from "react-query/hydration";
import { HelmetProvider } from "react-helmet-async";
import createCache from "@emotion/cache";
import { CacheProvider } from "@emotion/react";
import { Router, useRouter } from "next/router";
import { Provider } from "next-auth/client";
import { CssBaseline } from "@material-ui/core";
import { AuthProvider } from "../context/AmplifyContext";
import { WidthProvider } from "../context/WindowSizeContext";
import { SettingsProvider } from "../src/contexts/SettingsContext";
import { DateDropdownProvider } from "../context/DateDropdownContext/DateDropdownContext";

import "../src/configureAmplify";
import { StoresProvider } from "../context/StoresContext";
import { useOutsideClick } from "../src/utils/hooks";
import { RutterConnectionsProvider } from "../context/RutterConnectionsContext";
import { PinMetricsProvider } from "../context/PinMetricsContext/PinMetricsContext";
import Pages from "../src/PagesWrapper";
import { ErrorProvider } from "../context/ErrorContext";
import { SidebarCollapseProvider } from "../context/SidebarCollapseContext";
import { OnboardingStorageProvider } from "../context/OnboardingStorageContext";
import { useIntercom } from "../src/hooks/useIntercom";
import Intercom from "../src/components/Intercom/Intercom";
import WhiteLabelingProvider from "../src/hooks/useWhitelabeling";
import { OnboardingChecklistContextProvider } from "../context/OnboardingChecklistContext/OnboardingChecklistContext";

Router.events.on("routeChangeStart", nProgress.start);
Router.events.on("routeChangeError", nProgress.done);
Router.events.on("routeChangeComplete", nProgress.done);

export const cache = createCache({ key: "css", prepend: true });
export const primaryColor = "var(--primary)";

declare global {
  interface Window {
    Intercom: any;
  }
}

function MyApp({ Component, pageProps, ...otherProps }: AppProps) {
  const { trackIntercomEvent } = useIntercom();
  const adBlockDetected = useDetectAdBlock();
  const [queryClient] = React.useState(
    () =>
      new QueryClient({
        defaultOptions: {
          queries: {
            refetchOnWindowFocus: false,
            staleTime: 21600000,
            retry: false,
          },
        },
      }),
  );
  const [dismissed, setDismissed] = React.useState(false);
  const adblockRef = React.useRef<HTMLDivElement | null>(null);
  const router = useRouter();

  useOutsideClick(adblockRef, () => setDismissed(true));

  useEffect(() => {
    TagManager.initialize({ gtmId: "GTM-MSSG7ZP" });
    // Remove the server-side injected CSS.
    const jssStyles = document.querySelector("#jss-server-side");
    if (jssStyles) {
      jssStyles.parentElement?.removeChild(jssStyles);
    }
  }, []);

  const productTourId = router?.query?.onboarding_tour;

  useEffect(() => {
    if (trackIntercomEvent && productTourId) trackIntercomEvent("startTour", productTourId);
  }, [trackIntercomEvent, productTourId]);
  return (
    <CacheProvider value={cache}>
      <Head>
        <title>Loading...</title>
        <link rel="icon" href="/favicon.svg" type="image/svg+xml" />
        <meta name="viewport" content="initial-scale=1, width=device-width" />
      </Head>
      <HelmetProvider>
        <QueryClientProvider client={queryClient}>
          <AuthProvider>
            <StoresProvider>
              <WhiteLabelingProvider>
                <SidebarCollapseProvider>
                  {/* <StripeSubscriptionContextProvider> */}
                  <DateDropdownProvider>
                    <SettingsProvider>
                      <OnboardingStorageProvider>
                        <OnboardingChecklistContextProvider>
                          <WidthProvider>
                            <PinMetricsProvider>
                              <RutterConnectionsProvider>
                                <ErrorProvider>
                                  <Hydrate state={pageProps.dehydratedState}>
                                    <Provider session={pageProps.session}>
                                      {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
                                      <CssBaseline />
                                      <Toaster
                                        position="top-center"
                                        toastOptions={{
                                          style: { maxWidth: 500 },
                                        }}
                                      />
                                      {adBlockDetected && !dismissed && (
                                        <div className="adblock_alert">
                                          <div ref={adblockRef} id="adblock_msg_wrapper">
                                            <div>
                                              <h3>Ad Blocker Detected </h3>
                                              <p>
                                                We can see you may be using an ad blocker. You’ll
                                                need to disable your ad blocker to use StoreHero.
                                              </p>
                                            </div>
                                            <button
                                              onClick={() => setDismissed(true)}
                                              type="button"
                                            >
                                              Dismiss
                                            </button>
                                          </div>
                                        </div>
                                      )}
                                      <Intercom />
                                      <Pages
                                        Component={Component}
                                        pageProps={pageProps}
                                        {...otherProps}
                                      />
                                    </Provider>
                                  </Hydrate>
                                </ErrorProvider>
                              </RutterConnectionsProvider>
                            </PinMetricsProvider>
                          </WidthProvider>
                        </OnboardingChecklistContextProvider>
                      </OnboardingStorageProvider>
                    </SettingsProvider>
                  </DateDropdownProvider>
                  {/* </StripeSubscriptionContextProvider> */}
                </SidebarCollapseProvider>
              </WhiteLabelingProvider>
            </StoresProvider>
          </AuthProvider>
        </QueryClientProvider>
      </HelmetProvider>
    </CacheProvider>
  );
}
export default MyApp;
