import React, { useCallback, useEffect, useMemo, useState } from "react";
import toast from "react-hot-toast";
import { useRouter } from "next/router";
import { useStoresContext } from "../StoresContext";
import {
  PinnedCardOrderItem,
  PinnedCards,
  PinnedCardsServerResponse,
} from "../../lib/pinned/types";
import { useDateDropdown } from "../DateDropdownContext/DateDropdownContext";
import { LoadingCardId, TogglePinnedStatusParams } from "./types";
import { getEnumValueByTitle, getObjectKeyBySource } from "./helpers/helpers";
import { deleteCardFromState } from "./helpers/deleteCardFromState";
import { addCardToState } from "./helpers/addCardToState";
import { queryFetcher } from "../../src/APIResolvers";
import { useUpdatePinnedCard } from "./useUpdatePinnedCard";

type PinMetricsContextProps = {
  pinnedCardsData: PinnedCards;
  pinnedCardDisplayOrder: PinnedCardOrderItem[];
  loading: boolean;
  refetch: () => void;
  error: boolean;
  updatePinnedCardsDisplayOrder: (pinnedCardsDisplayOrder: PinnedCardOrderItem[]) => void;
  togglePinnedStatus: (newPinnedData: TogglePinnedStatusParams) => Promise<void>;
  isCardLoading: ({ cardKey, source }: LoadingCardId) => boolean;
  isCardPinned: ({ cardKey, source }: LoadingCardId) => boolean;
};

const PinMetricsContext = React.createContext<PinMetricsContextProps | undefined>(undefined);

function PinMetricsProvider(props: any) {
  const { selectedStore } = useStoresContext();
  const {
    dateRange: { from, to },
    compareRange: { prevStartDate, prevEndDate },
  } = useDateDropdown();

  const [pinnedCardsData, setPinnedCardsData] = useState<PinnedCards | null>(null);
  const [pinnedCardDisplayOrder, setPinnedCardDisplayOrder] = useState<
    PinnedCardOrderItem[] | null
  >();
  const [cardsLoading, setCardsLoading] = useState<boolean>(true);
  const [loadingCardIds, setLoadingCardIds] = useState<LoadingCardId[]>([]);
  const [cardsError, setCardsError] = useState<boolean>(false);
  const { onAddPinnedCard, onDeletePinnedCard, onUpdatePinnedCardsDisplayOrder } =
    useUpdatePinnedCard();

  const router = useRouter();

  const setLoaders = useCallback((newValue: boolean, newLoadingCardId?: LoadingCardId) => {
    if (newValue) {
      if (newLoadingCardId) return setLoadingCardIds((prev) => [...prev, newLoadingCardId]);
      setCardsLoading(true);
    } else {
      if (newLoadingCardId) {
        setLoadingCardIds((prev) =>
          prev.filter(
            (cardId) =>
              !(
                cardId.cardKey === newLoadingCardId.cardKey &&
                cardId.source === newLoadingCardId.source
              ),
          ),
        );
      }
      setCardsLoading(false);
    }
  }, []);

  const fetchPinnedCards = useCallback(
    async (newLoadingCardId?: LoadingCardId) => {
      if (selectedStore?.id) {
        setLoaders(true, newLoadingCardId);
        try {
          const baseCurrency = selectedStore?.metaAdsCurrencySetting?.label ?? "";
          const targetCurrency = selectedStore?.metaAdsCurrencySetting?.storeCurrency ?? "";
          const response = await queryFetcher(
            `/api/dashboard/pinned-cards?storeId=${selectedStore.id}&startDate=${from}&endDate=${to}&prevStartDate=${prevStartDate}&prevEndDate=${prevEndDate}&baseCurrency=${baseCurrency}&targetCurrency=${targetCurrency}`,
          );
          setLoaders(false, newLoadingCardId);

          if (response?.errorMessage) {
            setCardsError(true);
            return [];
          }
          return response;
        } catch {
          setCardsError(true);
          setLoaders(false, newLoadingCardId);
          return null;
        }
      }
    },
    [
      selectedStore?.id,
      selectedStore?.metaAdsCurrencySetting,
      setLoaders,
      from,
      to,
      prevStartDate,
      prevEndDate,
    ],
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const updatePinnedCardsDisplayOrder = (pinnedCardsDisplayOrder: PinnedCardOrderItem[]) => {
    onUpdatePinnedCardsDisplayOrder({
      storeId: selectedStore?.id || "",
      pinnedCardsDisplayOrder,
    });
  };

  const refetch = useCallback(() => {
    fetchPinnedCards().then((res: PinnedCardsServerResponse) => {
      if (res) {
        const cardsData = {
          ...res,
        };
        delete cardsData.pinnedCardsDisplayOrder;
        setPinnedCardsData(cardsData as PinnedCards);
        setPinnedCardDisplayOrder(res.pinnedCardsDisplayOrder);
      }
    });
  }, [fetchPinnedCards]);

  useEffect(() => {
    refetch();
  }, [refetch]);

  useEffect(() => {
    if (router.pathname === "/dashboard") {
      refetch();
    }
  }, [router.pathname, refetch]);

  const isCardPinned = useCallback(
    ({ cardKey, source }: LoadingCardId) => {
      const sourceKey = getObjectKeyBySource(source) as keyof typeof pinnedCardsData;
      if (pinnedCardsData && sourceKey && pinnedCardsData?.[sourceKey]) {
        return Object.keys(pinnedCardsData[sourceKey]).findIndex((key) => cardKey === key) > -1;
      }
      return false;
    },
    [pinnedCardsData],
  );

  const togglePinnedStatus = useCallback(
    async ({ source, cardKey, cardData }: TogglePinnedStatusParams) => {
      const id = { source, cardKey };
      const service = source;

      if (selectedStore?.id) {
        setLoaders(true, id);
        try {
          const metricId = getEnumValueByTitle(id.cardKey, service) as any;

          if (isCardPinned(id)) {
            await onDeletePinnedCard({
              storeId: selectedStore.id,
              service,
              metricId,
            });
            setPinnedCardsData((prevState) => deleteCardFromState(prevState as PinnedCards, id));
          } else {
            await onAddPinnedCard({
              storeId: selectedStore.id,
              service,
              metricId,
            });
            setPinnedCardsData((prevState) =>
              addCardToState(prevState as PinnedCards, {
                source,
                cardKey,
                cardData,
              }),
            );
          }
        } catch {
          toast.error("Something went wrong, try again later");
        }

        setLoaders(false, id);
      }
    },
    [selectedStore?.id, setLoaders, isCardPinned, onDeletePinnedCard, onAddPinnedCard],
  );

  const isCardLoading = useCallback(
    ({ cardKey, source }: LoadingCardId) =>
      loadingCardIds.findIndex((cardId) => cardId.cardKey === cardKey && cardId.source === source) >
      -1,
    [loadingCardIds],
  );

  const value = useMemo(
    () => ({
      pinnedCardDisplayOrder,
      pinnedCardsData,
      loading: cardsLoading,
      error: cardsError,
      isCardLoading,
      togglePinnedStatus,
      isCardPinned,
      refetch,
      updatePinnedCardsDisplayOrder,
    }),
    [
      refetch,
      updatePinnedCardsDisplayOrder,
      pinnedCardsData,
      pinnedCardDisplayOrder,
      cardsLoading,
      cardsError,
      isCardLoading,
      togglePinnedStatus,
      isCardPinned,
    ],
  );

  return <PinMetricsContext.Provider value={value} {...props} />;
}

function usePinMetrics() {
  const context = React.useContext(PinMetricsContext);
  if (context === undefined) {
    throw new Error("usePinMetrics must be used within an PinMetricsProvider");
  }
  return context;
}

export { PinMetricsProvider, usePinMetrics, PinMetricsContext };
