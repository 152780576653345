import { AdsOverviewChartsData } from "../lib/ads-overview";
import { GA4AccountProperty } from "../lib/google/ga4/types";
import { GA4Account, GA4Property } from "../src/components/Ga4ConnectionScreen/Ga4ConnectionButton";
import { ErrorType } from "../lib/errorTypes";
import { UserPermissions, UserRole } from "../lib/db/types";
import { CurrencyItem } from "../lib/db/meta-ads-currency-settings";

export type Card = {
  errorType?: ErrorType;
  message?: string;
  dates?: number[] | string[];
  value: string | number;
  prevPeriodTotal?: string | number;
  period_values: number[];
  growth: { value: string | number; is_growing: boolean } | undefined;
  prevPeriodValues?: number[];
};

export type DashboardData = {
  reachCard: Card;
  clicksCard?: Card;
  ordersCard?: Card;
  repeatOrdersCard: Card | null;
  salesCard: Card | null;
  salesChart: {
    current_period: ChartItem | undefined;
    previous_period: ChartItem | undefined;
  };
  transactionsByChannel: TransactionsByChannel[];
  sales: {
    actual: number;
    forecast: number;
  } | null;
  budget: {
    actual: number;
    forecast: number;
  };
  adSpendByChannel: {
    spendBreakdown: {
      [key: string]: number;
    };
    total: number;
  };
};

export enum GSCCardsKeys {
  impressions,
  url_clicks,
  url_ctr,
}

export type GSCCards = {
  [key in keyof typeof GSCCardsKeys]: Card | PinnedCard<GSCCardsKeys>;
};

type GrowthData = {
  value: string | number;
  is_growing: boolean;
};

export type GSCTableElement = {
  page_url: string;
  clicks: number | string;
  impressions: number | string;
  ctr: string;
  position: number | string;

  clicksGrowth: GrowthData;
  impressionsGrowth: GrowthData;
  positionGrowth: GrowthData;
  ctrGrowth: GrowthData;
};

export interface GoogleError {
  code: number;
  message: string;
}

export type APIGAAccountsResponse = {
  items: APIGAAccount[];
  error?: GoogleError;
};

export type APIGAPropertiesResponse = {
  items: APIGAProperty[];
  error?: GoogleError;
};

export type APIGAViewsResponse = {
  items: APIGAView[];
  error?: GoogleError;
};

export type APIGAAccount = {
  id: string;
  name: string;
  created: string;
};

export type APIGAProperty = {
  id: string;
  name: string;
  created: string;
  websiteUrl: string;
};

export type APIGAView = {
  id: string;
  name: string;
  websiteUrl: string;
  created: string;
  eCommerceTracking: boolean;
};
export type APIAView = {
  customerId: string;
  accountId: string;
};

export type APIGSCSitesResponse = {
  siteEntry: APIGSCSiteEntry[];
  error?: GoogleError;
};

export type APIGSCSiteEntry = {
  siteUrl: string;
  permissionLevel: "siteFullUser" | "siteOwner" | "siteRestrictedUser" | "siteUnverifiedUser";
};

export type GoogleAdsError = {
  code: number;
};

export type APIGADCustomersResponse = {
  resourceNames: string[];
  error?: any;
};

export type APIGADAccount = {
  customer: {
    resourceName: string;
    id: string;
    descriptiveName: string;
  };
  customerClient: {
    resourceName: string;
    level: "0" | "1";
    descriptiveName: string;
    currencyCode: string;
    id: string;
  };
};

export type APIGADCampaign = {
  campaign: {
    resourceName: string;
    name: string;
  };
  metrics: {
    clicks: string;
    conversions: number;
    costMicros: string;
    conversionsValue: string;
  };
};

export type APIGADkeyword = {
  adGroup: {
    status: string;
  };
  campaign: {
    resourceName: string;
    name: string;
  };
  metrics: {
    clicks: string;
    conversions: number;
    costMicros: string;
    conversionsValue: string;
  };
  adGroupCriterion: {
    resourceName: string;
    status: string;
    keyword: {
      text: string;
    };
  };
};

export type APIGADProduct = {
  campaign: {
    status: string;
  };
  metrics: {
    clicks: string;
    conversions: number;
    costMicros: string;
    conversionsValue: string;
  };
  segments: {
    productTitle: string;
  };
};

export type GADTableElement = {
  campaignName: string;
  cost: number;
  clicks: number;
  conversions: number;
  cpa: number;
  roas: number;
};

export type GADAdGroupsTableElement = {
  adGroupName: string;
  cost: number;
  clicks: number;
  conversions: number;
  cpa: number;
};

export type GADKeywordsTableElement = {
  keywordName: string;
  cost: number;
  clicks: number;
  conversions: number;
  cpa: number;
  roas: number;
  status: boolean;
};

export type GADProductTableElement = {
  productTitle: string;
  cost: number;
  clicks: number;
  conversions: number;
  cpa: number;
  roas: number;
  status: boolean;
};

export type GADAccount = {
  currencyCode: string;
  customerId: string;
  customerName: string;
  descriptiveName: string;
  id: string;
};

export type DashboardCard = {
  values:
    | {
        [key: string]: number;
      }
    | undefined;
  total: number;
};

export type Period = {
  startDate: string; //YYYY-MM-DD format
  endDate: string; //YYYY-MM-DD format
  prevStartDate?: string | any; //YYYY-MM-DD format
  prevEndDate?: string | any; //YYYY-MM-DD format
};

export type GAnRequestParams = Period & {
  viewId: string;
  token: string;
};

export type AppSyncOnboardingOptionsItem = {
  label: string;
  value: string;
  symbol?: string;
  id: string;
  createdAt: string;
  updatedAt: string;
};

export type Store = {
  id: string;
  storeName: string;
  storeURL: string;
  salesGoal: string;
  budgetGoal: string;
  isRutterDataSyncing: boolean;
  currency: AppSyncOnboardingOptionsItem;
  industry: AppSyncOnboardingOptionsItem;
  googleAnalyticsAccount?: APIGAAccount;
  googleAnalyticsProperty?: APIGAProperty;
  googleAnalyticsView?: APIGAView;
  Ga4AccountProperty?: GA4AccountProperty;
  attributionView?: APIAView;
  googleAdsAccount?: GADAccount;
  isFbTokenExpired?: boolean;
  GA4Account?: GA4Account;
  GA4Property?: GA4Property;
  klaviyoAPIKey?: string;
  rechargeAPIKey?: string;
  tiktokID?: number;
  facebookAdsAccount?: {
    name: string;
    id: string;
  };
  googleSearchAccount?: {
    siteUrl: string;
    permissionLevel: string;
  };
  isShopifyStoreConnected: boolean;
  isMagentoStoreConnected: boolean;
  isWoocommerceStoreConnected: boolean;
  isBigCommerceStoreConnected: boolean;
  isMonthlyNotificationsEnabled?: boolean;
  isWeeklyNotificationsEnabled?: boolean;
  isDailyNotificationsEnabled?: boolean;
  owner: string[] | string;
  role: UserRole | undefined;
  permissions: UserPermissions[] | undefined;
  agencyId?: string;
  createdAt: string;
  linkedPartnerId?: string;
  isOnboarding: boolean;
  metaAdsCurrencySetting?: CurrencyItem & {
    storeCurrency: string;
  };
};

export type APIGAnCardsDataObj = {
  date: string;
  users: number;
  newUsers: number;
  sessions: number;
  bounceRate: number;
  transactions: number;
  productsAddsToCart: number;
  pageViews: number;
  averageOrderValue: number;
  revenue: number;
  productCheckouts: number;
};

export type GAnCards = {
  sessions: Card;
  conversion_rate: Card;
  bounce_rate: Card;
  products_added_to_cart: Card;
  purchases: Card;
  users: Card;
  pageViews: Card;
  averageOrderValue: Card;
  revenue: Card;
  productCheckouts: Card;
};

export type GAnChart = {
  current_period: GAnChartItem;
  previous_period: GAnChartItem;
};

type GAnChartItem = {
  dates: string[];
  values: number[];
};

export type GAnTransactionsByProduct = {
  name: string;
  productPageviews: string;
  addsToCart: string;
  revenuePerPurchase: string;
  purchases: string;
  revenue: string;
};

export type ExportProductsDBResponse = {
  NAME: string;
  productID: string;
  PRICE: number;
  COGS: number;
  PACKAGING_FEE: number;
  PRODUCT_COST: string;
  TRANSACTION_FEE: number;
  SALES: number;
  BEP_ROAS: number;
  REVENUE: number;
  PRODUCT_MARGIN_PERCENT: number;
  PRODUCT_MARGIN: number;
  FULFILLMENT_COST: number;
  VID: string;
};

export type GAnTProduct = {
  id: string;
  productName: {
    name: string;
    sku: string;
  };
  stock: {
    percentage: number;
    stockNumbers: string | number;
  };
  price: string | number;
  cogs: string | number;
  packing_fee: string | number;
  product_cost: string | number;
  transactionFees: string | number;
  sales: string | number;
  bep_roas: string | number;
  revenue: string | number;
  margin: string | number;
  profit: string | number;
  // hasActions: boolean;
  variants: GAntProductsVariants[] | null;
  fulfillment: string | number;
  spend?: number;
  clicks?: number;
  cpc?: number;
  ["cost/conv."]?: number;
  roas?: number;
  conversions?: number;
  conversionValue?: number;
  conversionRate?: number;
  aov?: number;
  impressions?: number;
  clickToPurchaseRate?: number;
  contributionMargin?: number;
};

export type GAntProductsVariants = {
  id: string;
  productName: {
    name: string;
    sku: string;
  };
  stock: {
    percentage: number;
    stockNumbers: string;
  };
  price: string;
  cogs: string;
  packing_fee: string;
  product_cost: string;
  sales: string;
  bep_roas: string;
  revenue: string;
  margin: string;
  profit: string;
  transactionFees: string;
  fulfillment: string;
};

export type ProductSummary = {
  NAME: string;
  PRICE: string | number;
  COGS: string | number;
  "PACKAGING FEE": string | number;
  "PRODUCT COST": string | number;
  "TRANSACTION FEE": string | number;
  SALES: string | number;
  "BEP ROAS": string | number;
  REVENUE: string | number;
  "PRODUCT MARGIN %": string | number;
  "PRODUCT MARGIN": string | number;
  "FULFILLMENT COST": string | number;
  [key: string]: string | number;
};

export type OrdersSummary = {
  DATE: string;
  PAID: number;
  COGS: number;
  TAX: number;
  DISCOUNT: number;
  "SHIPPING PAID": number;
  "SHIPPING COST": number;
  FULFILLMENT: number;
  "TRANSACTION FEE": number;
  "PACKING FEE": number;
  "GROSS PROFIT": number;
  "PRODUCT COST": number;
  "GP MARGIN": number;
  [key: string]: string | number;
};

export type GAnTOrders = {
  orderId: string;
  date: string;
  customer: {
    name: string;
    sourceName: string;
  };
  paid: string | number;
  discount: string | number;
  shipping_charged: string | number;
  shipping_cost: string | number;
  fulfillment: string | number;
  cogs: string | number;
  packing_fees: string | number;
  productCost: string | number;
  transactions: string | number;
  gross_profit: string | number;
  ad_spend?: string | number;
  staff_other_costs?: string | number;
  net_profit?: string | number;
  gpmargin: string | number;
  npmargin?: string | number;
  proas?: string | number;
  variants: GAnTOrdersVariants[] | null;
  payment_status?: string;
  status: string;
  shipping_country: string | undefined;
  shipping_city: string | undefined;
  shipping_region: string | undefined;
  billing_country: string | undefined;
  customer_email: string;
  fultfillment_carrier: string;
  fulfillment_status: string;
  tax: string | number;
};

export type GAnTOrdersVariants = {
  id: string;
  product: string;
  quantity: number;
  paid: string | number;
  discount: string | number;
  cogs: string | number;
  // total_packing_fees: string | number;
  gross_profit: string | number;
  gpm: string | number;
};

export type GAnTransactionsBySource = {
  source: string;
  sessions: string;
  productPageviews: string;
  addsToCart: string;
  conversionRate: string | number;
  purchases: string;
  value: string;
};

export type ErrorMessage = { errorMessage: string };

export type FacebookAdsAccount = {
  name: string;
  id: string;
};

export type APIFbAccounts = {
  adaccounts?: {
    data: FacebookAdsAccount[];
    paging: {
      next?: string;
    };
  };
  data?: FacebookAdsAccount[];
  paging?: {
    next?: string;
  };
};

export type APIFBData = {
  data: APIFBDataItem[];
  summary: Omit<APIFBDataItem, "campaign_name">;
};

export type APIFBAdsetData = {
  spend: string;
  clicks: string;
  adset_name: string;
  actions: FBAction[];
  date_start: string;
  purchase_roas?: [
    {
      action_type: "omni_purchase";
      value: string;
    },
  ];
};

export type APIFBCardsData = {
  data: APIFBCardsDataItem[];
  summary: Omit<APIFBCardsDataItem, "account_name"> | undefined;
};

export type APIFBCardsDataItem = {
  spend: string;
  cpc: string; //cost per click
  reach: string;
  ctr: string;
  account_name: string;
  actions: FBAction[];
  action_values: FBAction[];
  purchase_roas: [
    {
      action_type: "omni_purchase";
      value: string;
    },
  ];
  cpm: string;
  clicks: string;
  date_start: string;
};

export type APIFBDataItem = {
  spend: string;
  clicks: string;
  reach: string;
  ctr: string;
  campaign_name: string;
  actions: FBAction[];
  purchase_roas?: [
    {
      action_type: "omni_purchase";
      value: string;
    },
  ];
  date_start: string;
};

export type FBAction = {
  action_type: string;
  value: string;
};

export type FBCards = {
  conversions: Card;
  spend: Card;
  costPerConversion: Card;
  costPerClick: Card;
  roas: Card;
  CPM: Card;
  conversionValue: Card;
  CTR: Card;
  avgOrderValue: Card;
  revenuePerLinkClick: Card;
};

export type FBCardValues = {
  costPerConversion: Omit<Card, "period_values">;
  conversions: Omit<Card, "period_values">;
  spend: Omit<Card, "period_values">;
  costPerClick: Omit<Card, "period_values">;
  roas: Omit<Card, "period_values">;
};

type FBTableData = {
  cost: number | string;
  clicks: number | string;
  conversions?: number | string;
  cpa: number | string;
  roas: number | string;
};

export type FBCampaignTableElement = FBTableData & {
  campaignName: string;
};

export type FBAdsetTableElement = FBTableData & {
  adGroupName: string;
};

export type FBKeywordTableElement = FBTableData & {
  keywordName: string;
};

export type FBProductTableElement = FBTableData & {
  productTitle: string;
};

export type RechargeTableElement = {
  percentageofnewsubscription: number | string;
  churnedsubscription: number | string;
  reactivatedsubscription: number | string;
  newsubscription: number | string;
  subscriptionrevenue: number | string;
  activesubscription: number | string;
  product_id: string;
  product_name: string;
};

export type APITransactionsByChannelDataObj = {
  channel: string;
  sessions: number;
  bounceRate: number;
  transactions: number;
};

export type TransactionsByChannel = {
  channel: string;
  sessions: {
    value: number;
    growth: { value: string | number; is_growing: boolean | string };
  };
  bounceRate: {
    value: string | number;
    growth: { value: string | number; is_growing: boolean | string };
  };
  transactions: {
    value: number;
    growth: { value: string | number; is_growing: boolean | string };
  };
};

export type FinancesCategory = "sales" | "budget";

export type AdsOverviewGroupsTableElement = {
  adGroupName: string;
  cost: number | string;
  clicks: number | string;
  conversions: number | string;
  cpa: number | string;
  roas: number | string;
  platform: "google" | "fb";
};

type GADOverviewCardsResp = {
  conversions: Card;
  spend: Card;
  costPerConversion: Card;
  costPerClick: Card;
  ROAS: Card;
};

export type ConversionsResp = {
  Google?: number | null;
  Facebook?: number | string;
  Instagram?: number | string;
};

export type AdsClicksOverview = {
  [key: string]: number;
  other: number;
};

export type AdsOverviewCampaignTableElement = {
  campaignName: string;
  cost: number | string;
  clicks: number | string;
  conversions: number | string;
  cpa: number | string;
  roas: number | string;
  platform: "google" | "fb";
};

export type AdsOverviewData = {
  cardsData: GADOverviewCardsResp;
  chartsData: AdsOverviewChartsData | undefined;
  performanceTableData: {
    campaignsTableData: AdsOverviewCampaignTableElement[];
    adGroupsTableData: AdsOverviewGroupsTableElement[];
  };
  conversionsData: ConversionsResp;
  clicksData: AdsClicksOverview | null;
};

export type PieChartData = {
  [key: string]: number;
  other: number;
};

export type APIGAnCardsResponse = [
  string,
  string,
  string,
  string,
  string,
  string,
  string,
  string,
  string,
  string,
  string,
][];

export type PieChart = {
  breakdown: {
    [key: string]: number;
  };
  total: number;
};

export type Chart = {
  current_period: ChartItem;
  previous_period: ChartItem;
};

export type OverviewChart = {
  sales: ChartItem;
  total_costs: ChartItem;
  cogs: ChartItem;
  net_profit: ChartItem;
  net_margin: ChartItem;
  current_period?: ChartItem;
  previous_period?: ChartItem;
};

export type ChartItem = {
  dates: string[];
  values: number[];
};

export enum GoogleAdsCards {
  spend,
  CPC,
  conversions,
  costPerConversion,
  ROAS,
  conversionValue,
  CTR,
  CPM,
  clicks,
  impressions,
  topOfPageImpression,
  absTopOfPageImpression,
  avgOrderValue,
}

export enum ReChargeCards {
  allordervalue,
  recurringorderaov,
  recurringordervalue,
  recurringordercount,
  checkoutorderaov,
  checkoutordervalue,
  totalactivesubscribers,
  totalactivesubscriptions,
  newsubscribers,
  reactivatedsubscribers,
  churnedsubscribers,
  avgactivesubscriptions,
  avgorders,
  avgactivedays,
  newsubscriptionsfromexistingsubscribers,
  cancellationsbeforefirstrecurringorder,
  churnedsubscribercancellationspercentage,
  churnedsubscribercancellations,
  newsubscriptions,
  activatedsubscriptions,
  churnedsubscriptions,
  netchange,
  netsubscriptionschange,
  avgorderspersubscription,
  avgactivedayspersubscription,
}

export enum TikTokAdsCards {
  spend,
  costPerClick,
  conversions,
  costPerConversion,
  conversionsValue,
  roas,
  purchase,
  purchaseValue,
}

export type PinnedCard<PinnedService> = Card & { cardId: PinnedService };
